import { Box, CloseButton, Container, Divider, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import StoreSearch from './store-search'
import StoreList from './store-list'
import { IoCloseOutline } from 'react-icons/io5'

const AtgStoreFinder = ({ isOpen, onClose, handelHeaderData }) => {
  const [storeData, setStoreData] = useState<any>()
  const [showNoResults, setShowNoResults] = useState(false)
  const handleOnStoreData = (data) => {
    setStoreData(data.storeData)
    setShowNoResults(data.showNoResults)
  }
  if (!isOpen) {
    return null
  }

  return (
    <>
      {
        <Container>
          <Box
            position="fixed"
            width={'100%'}
            height={'100%'}
            overflow={'hidden'}
            background="rgba(0, 0, 0, .3)"
            left={0}
            top={0}
            zIndex={9}
          ></Box>
          <Container
            bg={'#fff'}
            position={'absolute'}
            shadow={'md'}
            p={4}
            top={{ base: 4, sm: 4, md: 'auto' }}
            width={{ base: 'calc(100% - 2rem)', sm: 'calc(100% - 2rem)', md: '400px', lg: '400px' }}
            maxWidth={{ base: 'sm:100%', sm: '100%' }}
            zIndex={333}
            left={{ base: 4, lg: 'auto' }}
            py={4}
            _before={{
              md: {
                content: '""',
                position: 'absolute',
                height: '15px',
                width: '15px',
                transform: 'rotate(45deg)',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                top: '-8px',
                bg: '#fff',
              },
            }}
          >
            <Box fontWeight={'400'} display={'flex'} fontSize={'1.3rem'} justifyContent={'space-between'}>
              <Text tabIndex={0}>Find Store</Text>{' '}
              <CloseButton
                alignSelf="center"
                position="relative"
                marginLeft="auto"
                size="sm"
                _hover={{ bg: 'transparent' }}
                style={{ height: 20.8, width: 20.8 }}
                onClick={onClose}
              />
            </Box>
            <Divider my={4}></Divider>
            <StoreSearch shippingType="" onStoreData={handleOnStoreData}></StoreSearch>
            <StoreList
              storesLists={storeData}
              onClose={onClose}
              onheaderData={handelHeaderData}
              showNoResult={showNoResults}
            ></StoreList>
          </Container>
        </Container>
      }
    </>
  )
}

export default AtgStoreFinder
