import React, { useState } from 'react'
import { Box, Container, Divider, Text } from '@chakra-ui/react'
import { IoChevronDown, IoChevronUp, IoCloseOutline } from 'react-icons/io5'
import { atgPhoneMask } from './utils'
import StoreTiming from '../mystore/store-timing'

const StoreInformations = ({ onClose, storeData, onHandelLeave }) => {
  let todayStoreTiming = ''
  let showTimingpopup: boolean = false
  let weeks = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  const [showTiming, setShowTiming] = useState(false)
  const handelTiming = () => {
    if (showTiming) {
      setShowTiming(false)
    } else {
      setShowTiming(true)
    }
  }
  const filterTime = (time, filtertype, holidayTiming) => {
    let timing = time
    let timingHtml = ''
    // console.log("timing: ",timing.length);
    var day_of_week = new Date().getDay()
    if (filtertype == 'daysOpen') {
      for (var i = 0; i < timing.length; i++) {
        let splitString = timing[i].split('||')
        let day = splitString[0]
        if (splitString[1] == 'Closed') {
          timingHtml =
            timingHtml +
            '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
            day +
            '' +
            splitString[1] +
            '"><div class="text-capitalize font-weight-bold" >' +
            day +
            '</div><div class="font-weight-bold">' +
            splitString[1] +
            '</div></div>'
        } else {
          let startTime = splitString[1]
          let endTime = splitString[2]
          startTime = formatTime(startTime)
          endTime = formatTime(endTime)

          let today = new Date()
          let dd = String(today.getDate()).padStart(2, '0')
          let mm = String(today.getMonth() + 1).padStart(2, '0')
          let yyyy = today.getFullYear()
          let todayDate = yyyy + '-' + mm + '-' + dd
          //console.log("today date ",todayDate);
          let isTodayHolidayDate = false
          // console.log("day",day,day_of_week)
          if (weeks[day_of_week].indexOf(day) > -1) {
            if (holidayTiming && holidayTiming !== undefined && holidayTiming.length && holidayTiming.length > 0) {
              for (let i = 0; i < holidayTiming.length; i++) {
                //console.log("date",holidayTiming[i])
                if (holidayTiming[i].split('||')[0] == todayDate) {
                  isTodayHolidayDate = true
                  break
                }
              }
              if (isTodayHolidayDate) {
                timingHtml =
                  timingHtml +
                  '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
                  day +
                  '' +
                  startTime +
                  '-' +
                  endTime +
                  '"><div class="text-capitalize" >' +
                  day +
                  '</div><div>' +
                  startTime +
                  '-' +
                  endTime +
                  '</div></div>'
              } else {
                timingHtml =
                  timingHtml +
                  '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
                  day +
                  '' +
                  startTime +
                  '-' +
                  endTime +
                  '"><div class="text-capitalize font-weight-bold" >' +
                  day +
                  '</div><div class="font-weight-bold">' +
                  startTime +
                  '-' +
                  endTime +
                  '</div></div>'
              }
            } else {
              //console.log("holidayTiming else",holidayTiming[i])
              timingHtml =
                timingHtml +
                '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
                day +
                '' +
                startTime +
                '-' +
                endTime +
                '"><div class="text-capitalize font-weight-bold">' +
                day +
                '</div><div class="font-weight-bold">' +
                startTime +
                '-' +
                endTime +
                '</div></div>'
            }
          } else {
            timingHtml =
              timingHtml +
              '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
              day +
              '' +
              startTime +
              '-' +
              endTime +
              '"><div class="text-capitalize">' +
              day +
              '</div><div>' +
              startTime +
              '-' +
              endTime +
              '</div></div>'
          }
        }
      }
    } else if (filtertype == 'holydayOpen') {
      for (let i = 0; i < timing.length; i++) {
        let splitString = timing[i].split('||')
        let day = splitString[0]
        let newDate = day.split('-')[1] + '-' + day.split('-')[2] + '-' + day.split('-')[0]
        day = newDate
        let startTime = splitString[1]
        let endTime = splitString[2]
        startTime = formatTime(startTime)
        endTime = formatTime(endTime)
        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0')
        let mm = String(today.getMonth() + 1).padStart(2, '0')
        let yyyy = today.getFullYear()
        let todayDate = yyyy + '-' + mm + '-' + dd
        //console.log("day",day,todayDate)
        if (splitString[0] == todayDate) {
          // console.log("datetet ",day,todayDate);
          timingHtml =
            timingHtml +
            '<div tabindex="0" role="application" class="d-flex justify-content-between"><div class="text-capitalize font-weight-bold">' +
            day +
            '</div><div class="font-weight-bold">' +
            startTime +
            '-' +
            endTime +
            '</div></div>'
        } else {
          timingHtml =
            timingHtml +
            '<div tabindex="0" role="application" class="d-flex justify-content-between"><div class="text-capitalize">' +
            day +
            '</div><div>' +
            startTime +
            '-' +
            endTime +
            '</div></div>'
        }
      }
    }
    return timingHtml
  }

  const getTodayTiming = (time, holidayTime) => {
    let timing = time
    let todayTimingHtml = ''
    // console.log("timing: ",timing.length);
    let today = new Date()
    let dd = String(today.getDate()).padStart(2, '0')
    let mm = String(today.getMonth() + 1).padStart(2, '0')
    let yyyy = today.getFullYear()

    let todayDate = yyyy + '-' + mm + '-' + dd
    ///console.log("today date ",todayDate);
    if (timing && timing != undefined && timing.length && timing.length > 0) {
      for (let i = 0; i < timing.length; i++) {
        let splitString = timing[i].split('||')
        if (splitString[1] != 'Closed') {
          let day = splitString[0]
          let startTime = splitString[1]
          let endTime = splitString[2]
          startTime = formatTime(startTime)
          endTime = formatTime(endTime)

          if (weeks[today.getDay()].indexOf(day) > -1) {
            if (holidayTime && holidayTime !== undefined && holidayTime.length && holidayTime.length > 0) {
              for (let i = 0; i < holidayTime.length; i++) {
                // console.log("date",holidayTime[i])
                if (holidayTime[i].split('||')[0] == todayDate) {
                  //console.log("in");
                  let holidayStartTime = holidayTime[i].split('||')[1]
                  let holidayEndTime = holidayTime[i].split('||')[2]
                  holidayStartTime = formatTime(holidayStartTime)
                  holidayEndTime = formatTime(holidayEndTime)
                  todayStoreTiming =
                    '<div class="font-weight-bold" tabindex="0" aria-label="' +
                    holidayStartTime +
                    '-' +
                    holidayEndTime +
                    '">' +
                    holidayStartTime +
                    '-' +
                    holidayEndTime +
                    '</div>'
                  break
                } else {
                  //console.log("in else");
                  todayStoreTiming =
                    '<div class="font-weight-bold" tabindex="0" aria-label="' +
                    startTime +
                    '-' +
                    endTime +
                    '">' +
                    startTime +
                    '-' +
                    endTime +
                    '</div>'
                }
              }
            } else {
              todayStoreTiming =
                '<div class="font-weight-bold" tabindex="0" aria-label="' +
                startTime +
                '-' +
                endTime +
                '">' +
                startTime +
                '-' +
                endTime +
                '</div>'
            }
          }
        }
      }
    } else if (
      holidayTime &&
      holidayTime !== undefined &&
      holidayTime.length &&
      holidayTime.length > 0 &&
      timing.length == 0
    ) {
      //console.log("in holiday")
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0')
      let mm = String(today.getMonth() + 1).padStart(2, '0')
      let yyyy = today.getFullYear()
      let todayDate = yyyy + '-' + mm + '-' + dd
      for (let i = 0; i < holidayTime.length; i++) {
        // console.log("date",holidayTime[i])
        let holidayStartTime = holidayTime[i].split('||')[1]
        let holidayEndTime = holidayTime[i].split('||')[2]
        if (holidayTime[i].split('||')[0] == todayDate) {
          //console.log("in");
          holidayStartTime = formatTime(holidayStartTime)
          holidayEndTime = formatTime(holidayEndTime)
          todayStoreTiming = '<div class="font-weight-bold">' + holidayStartTime + '-' + holidayEndTime + '</div>'
          break
        }
      }
    }
    return todayStoreTiming
  }

  const formatTime = (timeString) => {
    const [hourString, minute] = timeString.split(':')
    const hour = +hourString % 24
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM')
  }
  const handelLeave = () => {
    onHandelLeave(false)
  }
  return (
    <>
      {storeData && (
        <Container>
          <Box position="fixed" width={'100%'} height={'100%'} overflow={'hidden'} left={0} top={0} zIndex={9}></Box>
          <Container
            onMouseLeave={handelLeave}
            bg={'#fff'}
            position={'absolute'}
            shadow={'md'}
            p={'0.8rem  !important'}
            top={{ base: 12, sm: 12, md: 'auto' }}
            width={{ base: '300px', xs: '320px', sm: '320px', md: '320px' }}
            textAlign={'left'}
            zIndex={333}
            left={{ base: '-35px', xs: 4, sm: 2, md: 1, lg: 1 }}
            py={4}
            fontSize={'0.8125rem'}
            border={'1px solid #ccc'}
            _before={{
              content: '""',
              position: 'absolute',
              height: '15px',
              width: '15px',
              transform: 'rotate(45deg)',
              borderRight: '1px solid #fff',
              borderBottom: '1px solid #fff',
              borderTop: '1px solid #ccc',
              borderLeft: '1px solid #ccc',
              top: '-8px',
              bg: '#fff',
              left: '40px',
            }}
          >
            <Box fontWeight={'400'} display={'flex'} justifyContent={'space-between'}>
              <Text textAlign={'left'} whiteSpace={'normal'}>
                {storeData.address1}
                <br /> {storeData?.city} {storeData.state}
              </Text>
              <Text cursor={'pointer'} onClick={onClose} display={'flex'} marginTop={'1.25rem'}>
                &#9742; {atgPhoneMask(storeData.phoneNumber)}
              </Text>
            </Box>
            <Text
              cursor={'pointer'}
              onClick={onClose}
              position={'absolute'}
              right={'4px'}
              top={'4px'}
              fontSize={'20px'}
            >
              <IoCloseOutline />
            </Text>
            <Divider my={3}></Divider>
            <Box>
              {storeData?.daysOpenIntervals?.length > 0 && (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: getTodayTiming(storeData?.daysOpenIntervals, storeData?.holidayOpenIntervals),
                  }}
                ></Box>
              )}

              <>{storeData?.additionalHoursText && <Box>{storeData?.additionalHoursText}</Box>}</>

              {storeData?.daysOpenIntervals?.length > 0 && (
                <Container marginTop={3} p={0}>
                  <Box display={'flex'} fontWeight={500} onClick={handelTiming}>
                    See Store Hours{' '}
                    <Box fontSize={'16px'} marginTop={'2px'}>
                      {!showTiming ? <IoChevronDown /> : <IoChevronUp />}
                    </Box>
                  </Box>
                  <Box
                    style={{ display: showTiming ? 'block' : 'none' }}
                    fontWeight={400}
                    dangerouslySetInnerHTML={{
                      __html: filterTime(storeData?.daysOpenIntervals, 'daysOpen', storeData?.holidayOpenIntervals),
                    }}
                  ></Box>
                </Container>
              )}
            </Box>
          </Container>
        </Container>
      )}
    </>
  )
}

export default StoreInformations
