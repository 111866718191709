export const phoneMask = (value) => {
  if (value && value.length) {
    let code = value.slice(0, 1)
    let city = value.slice(2, 5)
    let number = value.slice(5)
    number = number.slice(0, 3) + '-' + number.slice(3)
    return (' (' + city + ') ' + number).trim()
  } else {
    return value
  }
}

export const atgPhoneMask = (value) => {
  if (value && value.length) {
    let city = value.slice(0, 3)
    let number = value.slice(3)
    number = number.slice(0, 3) + '-' + number.slice(3)
    return (' (' + city + ') ' + number).trim()
  } else {
    return value
  }
}

let weeks = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const filterTime = (time, filtertype, holidayTiming) => {
  let timing = time
  let timingHtml = ''
  // console.log("timing: ",timing.length);
  var day_of_week = new Date().getDay()
  if (filtertype == 'daysOpen') {
    for (var i = 0; i < timing.length; i++) {
      let splitString = timing[i].split('||')
      let day = splitString[0]
      if (splitString[1] == 'Closed') {
        timingHtml =
          timingHtml +
          '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
          day +
          '' +
          splitString[1] +
          '"><div class="text-capitalize font-weight-bold" >' +
          day +
          '</div><div class="font-weight-bold">' +
          splitString[1] +
          '</div></div>'
      } else {
        let startTime = splitString[1]
        let endTime = splitString[2]
        startTime = formatTime(startTime)
        endTime = formatTime(endTime)

        let today = new Date()
        let dd = String(today.getDate()).padStart(2, '0')
        let mm = String(today.getMonth() + 1).padStart(2, '0')
        let yyyy = today.getFullYear()
        let todayDate = yyyy + '-' + mm + '-' + dd
        //console.log("today date ",todayDate);
        let isTodayHolidayDate = false
        // console.log("day",day,day_of_week)
        if (weeks[day_of_week].indexOf(day) > -1) {
          if (holidayTiming && holidayTiming !== undefined && holidayTiming.length && holidayTiming.length > 0) {
            for (let i = 0; i < holidayTiming.length; i++) {
              //console.log("date",holidayTiming[i])
              if (holidayTiming[i].split('||')[0] == todayDate) {
                isTodayHolidayDate = true
                break
              }
            }
            if (isTodayHolidayDate) {
              timingHtml =
                timingHtml +
                '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
                day +
                '' +
                startTime +
                '-' +
                endTime +
                '"><div class="text-capitalize" >' +
                day +
                '</div><div>' +
                startTime +
                '-' +
                endTime +
                '</div></div>'
            } else {
              timingHtml =
                timingHtml +
                '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
                day +
                '' +
                startTime +
                '-' +
                endTime +
                '"><div class="text-capitalize font-weight-bold" >' +
                day +
                '</div><div class="font-weight-bold">' +
                startTime +
                '-' +
                endTime +
                '</div></div>'
            }
          } else {
            //console.log("holidayTiming else",holidayTiming[i])
            timingHtml =
              timingHtml +
              '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
              day +
              '' +
              startTime +
              '-' +
              endTime +
              '"><div class="text-capitalize font-weight-bold">' +
              day +
              '</div><div class="font-weight-bold">' +
              startTime +
              '-' +
              endTime +
              '</div></div>'
          }
        } else {
          timingHtml =
            timingHtml +
            '<div tabindex="0" role="application" class="d-flex justify-content-between" aria-label="' +
            day +
            '' +
            startTime +
            '-' +
            endTime +
            '"><div class="text-capitalize">' +
            day +
            '</div><div>' +
            startTime +
            '-' +
            endTime +
            '</div></div>'
        }
      }
    }
  } else if (filtertype == 'holydayOpen') {
    for (let i = 0; i < timing.length; i++) {
      let splitString = timing[i].split('||')
      let day = splitString[0]
      let newDate = day.split('-')[1] + '-' + day.split('-')[2] + '-' + day.split('-')[0]
      day = newDate
      let startTime = splitString[1]
      let endTime = splitString[2]
      startTime = formatTime(startTime)
      endTime = formatTime(endTime)
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0')
      let mm = String(today.getMonth() + 1).padStart(2, '0')
      let yyyy = today.getFullYear()
      let todayDate = yyyy + '-' + mm + '-' + dd
      //console.log("day",day,todayDate)
      if (splitString[0] == todayDate) {
        // console.log("datetet ",day,todayDate);
        timingHtml =
          timingHtml +
          '<div tabindex="0" role="application" class="d-flex justify-content-between"><div class="text-capitalize font-weight-bold">' +
          day +
          '</div><div class="font-weight-bold">' +
          startTime +
          '-' +
          endTime +
          '</div></div>'
      } else {
        timingHtml =
          timingHtml +
          '<div tabindex="0" role="application" class="d-flex justify-content-between"><div class="text-capitalize">' +
          day +
          '</div><div>' +
          startTime +
          '-' +
          endTime +
          '</div></div>'
      }
    }
  }
  return timingHtml
}

export const getTodayTiming = (time, holidayTime) => {
  let timing = time
  let todayStoreTiming = ''
  // console.log("timing: ",timing.length);
  let today = new Date()
  let dd = String(today.getDate()).padStart(2, '0')
  let mm = String(today.getMonth() + 1).padStart(2, '0')
  let yyyy = today.getFullYear()

  let todayDate = yyyy + '-' + mm + '-' + dd
  ///console.log("today date ",todayDate);
  if (timing && timing != undefined && timing.length && timing.length > 0) {
    for (let i = 0; i < timing.length; i++) {
      let splitString = timing[i].split('||')
      if (splitString[1] != 'Closed') {
        let day = splitString[0]
        let startTime = splitString[1]
        let endTime = splitString[2]
        startTime = formatTime(startTime)
        endTime = formatTime(endTime)

        if (weeks[today.getDay()].indexOf(day) > -1) {
          if (holidayTime && holidayTime !== undefined && holidayTime.length && holidayTime.length > 0) {
            for (let i = 0; i < holidayTime.length; i++) {
              // console.log("date",holidayTime[i])
              if (holidayTime[i].split('||')[0] == todayDate) {
                //console.log("in");
                let holidayStartTime = holidayTime[i].split('||')[1]
                let holidayEndTime = holidayTime[i].split('||')[2]
                holidayStartTime = formatTime(holidayStartTime)
                holidayEndTime = formatTime(holidayEndTime)
                todayStoreTiming =
                  '<div class="font-weight-bold" tabindex="0" aria-label="' +
                  holidayStartTime +
                  '-' +
                  holidayEndTime +
                  '">' +
                  holidayStartTime +
                  '-' +
                  holidayEndTime +
                  '</div>'
                break
              } else {
                //console.log("in else");
                todayStoreTiming =
                  '<div class="font-weight-bold" tabindex="0" aria-label="' +
                  startTime +
                  '-' +
                  endTime +
                  '">' +
                  startTime +
                  '-' +
                  endTime +
                  '</div>'
              }
            }
          } else {
            todayStoreTiming =
              '<div class="font-weight-bold" tabindex="0" aria-label="' +
              startTime +
              '-' +
              endTime +
              '">' +
              startTime +
              '-' +
              endTime +
              '</div>'
          }
        }
      }
    }
  } else if (
    holidayTime &&
    holidayTime !== undefined &&
    holidayTime.length &&
    holidayTime.length > 0 &&
    timing.length == 0
  ) {
    //console.log("in holiday")
    let today = new Date()
    let dd = String(today.getDate()).padStart(2, '0')
    let mm = String(today.getMonth() + 1).padStart(2, '0')
    let yyyy = today.getFullYear()
    let todayDate = yyyy + '-' + mm + '-' + dd
    for (let i = 0; i < holidayTime.length; i++) {
      // console.log("date",holidayTime[i])
      let holidayStartTime = holidayTime[i].split('||')[1]
      let holidayEndTime = holidayTime[i].split('||')[2]
      if (holidayTime[i].split('||')[0] == todayDate) {
        //console.log("in");
        holidayStartTime = formatTime(holidayStartTime)
        holidayEndTime = formatTime(holidayEndTime)
        todayStoreTiming = '<div class="font-weight-bold">' + holidayStartTime + '-' + holidayEndTime + '</div>'
        break
      }
    }
  }
  return todayStoreTiming
}

export const formatTime = (timeString) => {
  const [hourString, minute] = timeString.split(':')
  const hour = +hourString % 24
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM')
}

export const storeTimingPopup = (event, id, index) => {
  let ele = document.getElementById(id)
  let matIconId = 'matIconTiming' + index
  let matIcon = document.getElementById(matIconId)
  let storeTextId = 'storeText' + index
  let storeTextEle = document.getElementById(storeTextId)
  if (ele && ele.classList.contains('d-block') && matIcon.classList.contains('arrow_ups')) {
    ele.classList.remove('d-block')
    ele.classList.add('d-none')
    storeTextEle.setAttribute('aria-label', 'See Store Hours Please enter to expand')
    matIcon.classList.remove('arrow_ups')
  } else {
    ele.classList.remove('d-none')
    ele.classList.add('d-block')
    storeTextEle.setAttribute('aria-label', 'See Store Hours Please enter to collapse')
    matIcon.classList.add('arrow_ups')
  }
}

export const holidayStoreTimingPopup = (event, id, index) => {
  let ele = document.getElementById(id)
  let matIconId = 'holidayMatIconTiming' + index
  let matIcon = document.getElementById(matIconId)
  if (ele && ele.classList.contains('d-block') && matIcon.classList.contains('arrow_ups')) {
    ele.classList.remove('d-block')
    ele.classList.add('d-none')
    matIcon.classList.remove('arrow_ups')
  } else {
    ele.classList.remove('d-none')
    ele.classList.add('d-block')
    matIcon.classList.add('arrow_ups')
  }
}
