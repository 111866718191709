import React, { FormEvent, useState } from 'react'
import {
  Box,
  Button,
  SelectField,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Text,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import fetch from 'unfetch'
import * as yup from 'yup'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { useFormat } from 'helpers/hooks/useFormat'

const schema = yup.object().shape({
  location: yup.string().required('Please enter City, State, or Zip Code'),
})

import { utagLink } from 'helpers/tealiumHelper'
import { core } from 'composable/chakra/figma-tokens'
import { GOOGLE_MAP_API_KEY } from '../general'

const StoreSearch = ({ onStoreData, shippingType }) => {
  const toast = useToast()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  })
  const locationValue = watch('location')
  const intl = useFormat({ name: 'common' })
  let distances = [
    { value: '25', viewValue: '25 miles' },
    { value: '50', viewValue: '50 miles' },
    { value: '100', viewValue: '100 miles' },
    { value: '200', viewValue: '200 miles' },
  ]
  const [location, setLocation] = useState('')
  const [distance, setDistance] = useState('25')
  const formLabel = {
    color: 'var(--semantic-text-primary, #15191C)',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '500',
  }
  const isError = location === ''
  let addressLocation: any
  const getLatLng = async (location: string) => {
    let geoMapUrl = 'https://maps.googleapis.com/maps/api/geocode/json?key=' + GOOGLE_MAP_API_KEY

    geoMapUrl = `${geoMapUrl}&address=${location}`

    const res = await fetch(geoMapUrl, {
      method: 'GET',
    })
    const data = await res.json()
    if (data.status == 'OK') {
      addressLocation = {}
      addressLocation = data.results[0].geometry.location
      findStoresNearBy(distance)
    } else if (data.status == 'ZERO_RESULTS') {
      onStoreData({ storeData: {}, showNoResults: true })
      toast.closeAll()
      toast({
        description: 'No store found for searched data',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-left',
      })
    } else {
      onStoreData({ storeData: {}, showNoResults: true })
      toast.closeAll()
      toast({
        description: 'No store found for searched data',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-left',
      })
    }
  }

  const findStoresNearBy = (distance) => {
    if (addressLocation) {
      let obj = {
        ...shippingType,
        lat: addressLocation.lat + '',
        lng: addressLocation.lng + '',
        radius: distance,
      }
      atgAxiosInstance
        .post('stores/search', obj)
        .then((res) => {
          const resObj = { storeData: res }
          onStoreData({ ...resObj, showNoResults: false })
        })
        .catch((error) => {
          onStoreData({ storeData: {}, showNoResults: true })
          if (error?.response?.data && error?.response?.data.status == '417') {
            toast.closeAll()
            toast({
              description: error?.response?.data.title,
              status: 'error',
              duration: 6000,
              isClosable: true,
              position: 'top-left',
              containerStyle: {
                minWidth: '330px',
              },
            })
          } else if (error?.response?.data.error && error?.response?.data.error['o:errorPath']) {
            toast.closeAll()
            toast({
              description: error?.response?.data.error['title'] + '   ',
              status: 'error',
              duration: 6000,
              isClosable: true,
              position: 'top-left',
              containerStyle: {
                minWidth: '330px',
              },
            })
          }
        })
    }
  }

  async function onSubmit(data): Promise<void> {
    utagLink([
      'link',
      {
        tealium_event: 'store_find',
      },
    ])
    getLatLng(data.location)
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ md: 2, sm: 1, xs: 1 }} spacing={2}>
          <Box>
            <FormControl isInvalid={!!errors.location} variant={'floating'}>
              <Input
                className="border bg-transparent font-normal"
                borderColor={errors.location ? 'red !important' : '#CACFD7'}
                style={{ borderRadius: '0px', width: '100%' }}
                {...register('location')}
                placeholder=" "
                type="text"
                id="location"
                paddingLeft={'8px'}
                paddingRight={'6px'}
                boxShadow={'none'}
                height={'45px'}
              />
              <FormLabel style={formLabel}>City, State, or Zip Code*</FormLabel>
              <FormErrorMessage textAlign={'left'} fontWeight={'500'} fontSize={'80%'} mt={1}>
                Please enter City, State or Zip Code
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box marginTop={{ base: 4, sm: 4, md: 0, lg: 0 }}>
            <FormControl isInvalid={isError} variant={'floating'}>
              <SelectField
                w="100%"
                height={'45px'}
                borderColor={'#CACFD7'}
                onChange={(e) => setDistance(e.target.value)}
                style={{ borderRadius: '0px', fontWeight: 'normal', paddingBottom: '0.36rem' }}
              >
                {distances.map((value, index) => {
                  return (
                    <option key={index} value={value.value}>
                      {value.viewValue}
                    </option>
                  )
                })}
              </SelectField>
              <FormLabel
                style={formLabel}
                position={'absolute'}
                left={3}
                top={4}
                backgroundColor={'white'}
                padding={'0 4px'}
                color={'black'}
                zIndex={1}
                transform={'scale(0.85) translateY(-26px)'}
                transition={'transform 0.5s ease-in-out'}
                transformOrigin={'left top'}
                fontSize={14}
                fontWeight={400}
                fontFamily={'Libre Franklin'}
                textColor={core.primary['primary-500']}
              >
                Distance*
              </FormLabel>
            </FormControl>
          </Box>
        </SimpleGrid>
        <Button
          type="submit"
          bgColor="#000"
          isDisabled={!locationValue}
          _disabled={{ opacity: 0.7 }}
          _hover={{ backgroundColor: 'black' }}
          borderRadius={0}
          my={'16px'}
          fontSize={'0.875rem'}
          width="100%"
        >
          FIND STORES
        </Button>
      </form>
    </>
  )
}

export default StoreSearch
