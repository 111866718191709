import { Box, Divider, Text } from '@chakra-ui/react'
import React from 'react'
import StoreListItem from './store-list-item'
import atgAxiosInstance from 'helpers/axios-atg-helper'

const StoreList = ({ storesLists, onClose, onheaderData, showNoResult }) => {
  let selectedStoreD: any
  let selectedStoreData = typeof localStorage !== 'undefined' ? localStorage.getItem('selectedStoreData') : null
  if (selectedStoreData) {
    selectedStoreD = JSON.parse(selectedStoreData)
  }
  const handelStore = (storeData) => {
    const obj = {
      storeId: storeData.storeId,
    }
    atgAxiosInstance
      .post('currentUser/myAccount/saveStore', obj)
      .then((res) => {
        atgAxiosInstance
          .get('currentUser/header')
          .then((res) => {
            onheaderData(res.data)
          })
          .catch((error) => {})
        onClose(false)
      })
      .catch((error) => {})
  }

  return (
    <>
      {storesLists && storesLists?.data?.stores?.length > 0 && <Divider my={4}></Divider>}
      <Box maxHeight={'400px'} overflow={'auto'}>
        {storesLists && storesLists?.data?.stores?.length > 0 ? (
          <>
            {storesLists.data?.stores?.map((store, idx) => {
              return <StoreListItem storeData={store} onHandleStore={handelStore} key={idx} />
            })}
          </>
        ) : (
          <>{showNoResult && <Text>No store found for searched data</Text>}</>
        )}
      </Box>
    </>
  )
}

export default StoreList
