import { Box, Button, Container, HStack, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5'
import { filterTime, getTodayTiming, atgPhoneMask } from './utils'

export interface StoreListItemProps {
  storeData: any
  idx?: number
  onHandleStore: any
}
const StoreListItem = ({ storeData, idx, onHandleStore }: StoreListItemProps) => {
  const handelStore = (store) => {
    onHandleStore(store)
  }
  const [show, setShow] = useState(false)
  const onClickShow = () => {
    setShow(!show)
  }
  const distances = Math.round(storeData?.distance)
  return (
    <Box key={idx}>
      <Container>
        <HStack spacing={4} fontSize={'0.8125rem'} alignItems={'initial'} fontWeight={500}>
          <VStack
            textAlign={'left'}
            spacing={4}
            whiteSpace={'normal'}
            alignItems={'initial'}
            fontWeight={'500 !important'}
          >
            <Text>{storeData?.displayStoreNumber}</Text>
            <Text>
              {storeData?.address1}, {storeData?.city}, {storeData?.state}, {storeData?.zipCode}
            </Text>
            <Text>{atgPhoneMask(storeData?.phoneNumber)}</Text>
            <Text
              dangerouslySetInnerHTML={{
                __html: getTodayTiming(storeData?.daysOpenIntervals, storeData?.holidayOpenIntervals),
              }}
            ></Text>
          </VStack>
          <VStack fontSize={'14px'} spacing={4} color={'#76797E'} alignItems={'end'}>
            <Text float={'right'} color={'text.primary'}>
              {distances} miles
            </Text>
            <Button onClick={() => handelStore(storeData)} borderRadius={0} fontSize={'0.875rem'}>
              CHOOSE STORE
            </Button>
          </VStack>
        </HStack>
        <Box alignItems={'flex-start'}>
          {storeData?.additionalHoursText && (
            <Text>
              <b>{storeData?.additionalHoursText}</b>
            </Text>
          )}

          {storeData?.daysOpenIntervals &&
            storeData?.daysOpenIntervals?.length &&
            storeData?.daysOpenIntervals?.length > 0 && (
              <>
                <Box mt={2} onClick={onClickShow}>
                  <Text display={'flex'} cursor={'pointer'} onClick={onClickShow}>
                    See store hours{' '}
                    {!show ? <IoChevronDownOutline fontSize={16} /> : <IoChevronUpOutline fontSize={16} />}{' '}
                  </Text>
                </Box>
              </>
            )}

          {show && (
            <Text
              mt={2}
              fontWeight={'400'}
              dangerouslySetInnerHTML={{
                __html: filterTime(storeData?.daysOpenIntervals, 'daysOpen', storeData?.holidayOpenIntervals),
              }}
            ></Text>
          )}
        </Box>
        <Box my={4}>
          <hr></hr>
        </Box>
      </Container>
    </Box>
  )
}

export default StoreListItem
